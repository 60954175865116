import { useTrack } from "use-analytics";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import env from "config";

import Button from "components/Button";
import Modal from "components/Modal";
import NavTab from "components/NavTab";
import FeedbackMessage from "components/FeedbackMessage";

import { useGetMessageQuery } from "features/message/messageApi";
import { IMessage } from "features/message/message.interface";

import EmailDetailInsight from "./EmailDetailInsight";
import EmailDetailInteractionTimeline from "./EmailDetailInteractionTimeline";
import EmailDetailOverview from "./EmailDetailOverview";
import { useTrackFilterOptions } from "utils/trackOptions";
import EmailDetailLinkTracking from "pages/track/email/detail/EmailDetailLinkTracking";
import downloadMessageAsCSV from "./downloadMessageAsCSV";
import { format } from "date-fns";

const BLAST_EMAIL_THRESHOLD =
  Number(env("REACT_APP_BLAST_EMAIL_THRESHOLD")) || 24;

type EmailDetailTab = "overview" | "insights" | "interactions" | "links";

const EmailDetailModal = ({ messageId }: { messageId: string }) => {
  const track = useTrack();
  const [tab, setTab] = useState<EmailDetailTab>("overview");
  const [trackOptions] = useTrackFilterOptions();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: detailMessage,
    error,
    isLoading,
    isFetching,
  } = useGetMessageQuery({
    id: messageId,
    trackOptions,
  });
  const messageNotFound = error && (error as any).status === 404;

  const showInsights =
    detailMessage &&
    detailMessage.stats.totalRecipients > BLAST_EMAIL_THRESHOLD;

  const handleClose = () => {
    navigate(location.pathname.split("/email/")[0]);
  };

  const handleExportMessage = () => {
    track("Downloaded email details as CSV", {
      category: "Data export",
      label: "CSV download",
    });
    downloadMessageAsCSV(detailMessage as IMessage);
  };

  const bodySwitcher = () => {
    switch (tab) {
      case "overview":
        return <EmailDetailOverview id={messageId} />;
      case "interactions":
        return (
          <EmailDetailInteractionTimeline
            id={messageId}
            sendTimestamp={detailMessage?.sendTimestamp}
            messageSubject={detailMessage?.messageSubject}
          />
        );
      case "insights":
        return <EmailDetailInsight id={messageId} />;
      case "links":
        return <EmailDetailLinkTracking id={messageId} />;
    }
  };

  return (
    <Modal
      width="xl"
      height="xl"
      usePadding={false}
      visible
      onClose={handleClose}
    >
      {messageNotFound ? (
        <div className="flex h-full flex-col">
          <div className="relative flex items-center justify-center pb-4 pt-6 text-center">
            <div
              data-testid="message-subject"
              className="flex-1 pl-16 pr-24 text-lg font-bold text-navy"
            >
              Email details
            </div>
            <div className="absolute right-4 top-4">
              <Button icon="icon-cross" color="dust" onClick={handleClose} />
            </div>
          </div>
          <FeedbackMessage
            icon="icon-warning-hex"
            title="We can't load Sent emails"
            paragraps={[
              "You may have an incorect URL, or the email may no longer be available in Relata Track.",
            ]}
          />
        </div>
      ) : (
        <div className="flex h-full flex-col pb-8">
          {isLoading || isFetching ? (
            <div className="relative flex flex-col items-center gap-1 pt-5">
              <div className="absolute right-4 top-4">
                <Button disabled icon="icon-cross" color="dust" />
              </div>
              <div className="h-6 w-44 rounded bg-dust" />
              <div className="flex flex-row gap-6 p-3">
                <div className="h-6 w-16 rounded bg-dust" />
                <div className="h-6 w-32 rounded bg-dust-light" />
                <div className="h-6 w-28 rounded bg-dust" />
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex items-center justify-center pb-2 pt-6 text-center">
                <div
                  data-testid="message-subject"
                  className="flex-1 truncate pl-16 pr-24 text-lg text-navy"
                  data-tooltip-content={
                    detailMessage?.sendTimestamp
                      ? `Sent ${format(new Date(detailMessage.sendTimestamp), "MMMM do, HH:mm")}`
                      : ""
                  }
                  data-tooltip-id="default-tooltip"
                >
                  {detailMessage?.messageSubject}
                </div>
                <div className="absolute right-4 top-4">
                  <div className="flex gap-2">
                    <Button
                      icon="icon-export"
                      color="dust"
                      tooltip="Export data"
                      onClick={handleExportMessage}
                      disabled={!detailMessage}
                    />
                    <Button
                      icon="icon-cross"
                      color="dust"
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-center gap-8 overflow-x-scroll border-b border-dust-dark bg-white-96">
                <NavTab
                  text="Overview"
                  active={tab === "overview"}
                  onClick={() => setTab("overview")}
                />
                {showInsights && (
                  <NavTab
                    text="Insights"
                    active={tab === "insights"}
                    onClick={() => setTab("insights")}
                  />
                )}
                <NavTab
                  text="Interaction timeline"
                  active={tab === "interactions"}
                  onClick={() => setTab("interactions")}
                />
                <NavTab
                  text="Link tracking"
                  active={tab === "links"}
                  onClick={() => setTab("links")}
                />
              </div>
            </div>
          )}
          {bodySwitcher()}
        </div>
      )}
    </Modal>
  );
};

export default EmailDetailModal;
