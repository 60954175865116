import { formatSeconds } from "utils/dates";
import DataTag, { DataTagType } from "components/atoms/DataTag";
import SelectOption from "components/SelectOption";
import { useState } from "react";

const AverageTimeMiniStatistic = ({
  myValue,
  myPrevious,
  allValue,
  allPrevious,
  myOutValue,
  myOutPrevious,
  allOutValue,
  allOutPrevious,
  myFirstValue,
  myFirstPrevious,
  allFirstValue,
  allFirstPrevious,
  isLoading,
}: {
  myValue?: any;
  myPrevious?: any;
  allValue?: any;
  allPrevious?: any;
  myOutValue?: any;
  myOutPrevious?: any;
  allOutValue?: any;
  allOutPrevious?: any;
  myFirstValue?: any;
  myFirstPrevious?: any;
  allFirstValue?: any;
  allFirstPrevious?: any;
  isLoading?: boolean;
}) => {
  const options = [
    { label: "Average response times for all emails", value: "all" },
    { label: "Average response times for first responses", value: "first" },
  ];
  const [selected, setSelected] = useState(options[0].value);

  const calculateValues = (currentValue: any, previousValue: any) => {
    const current = Number(currentValue) || 0;
    const previous = Number(previousValue) || 0;
    const delta = current - previous;
    const trend: DataTagType =
      current > 0 && delta > 0
        ? "increase"
        : current > 0 && delta < 0
          ? "decrease"
          : "consistent";
    const color: "green" | "red" | "gray" =
      current > 0 && delta > 0
        ? "red"
        : current > 0 && delta < 0
          ? "green"
          : "gray";
    return { current, previous, delta, trend, color };
  };

  if (isLoading)
    return (
      <div className="relative flex-1 w-full rounded-lg border border-dust-dark bg-white px-3 py-4">
        <SelectOption
          disabled
          options={options}
          value={selected}
          onChange={setSelected}
        />
        <div className="flex animate-pulse flex-col">
          {[1, 2, 3, 4].map((index) => (
            <div key={index} className="flex gap-2 pb-4 pl-2 pt-4">
              <div className="w-64 rounded h-3 animate-pulse bg-dust" />
            </div>
          ))}
        </div>
      </div>
    );
  const statistics: {
    label: string;
    current: number;
    previous: number;
    delta: number;
    trend: DataTagType;
    color: "green" | "red" | "gray";
    type: "all" | "first";
  }[] = [
    {
      label: "You respond to them",
      ...calculateValues(myOutValue, myOutPrevious),
      type: "all",
    },
    {
      label: "They respond to you",
      ...calculateValues(myValue, myPrevious),
      type: "all",
    },
    {
      label: "All colleagues respond to them",
      ...calculateValues(allOutValue, allOutPrevious),
      type: "all",
    },
    {
      label: "They respond to all colleagues",
      ...calculateValues(allValue, allPrevious),
      type: "all",
    },
    {
      label: "You respond to them",
      ...calculateValues(myFirstValue, myFirstPrevious),
      type: "first",
    },
    {
      label: "All colleagues respond to them",
      ...calculateValues(allFirstValue, allFirstPrevious),
      type: "first",
    },
  ];

  return (
    <div className="relative flex-1 w-full rounded-lg border border-dust-dark bg-white px-3 py-4">
      <SelectOption options={options} value={selected} onChange={setSelected} />
      <div className="space-y-2 py-2">
        {statistics
          .filter((statistic) => statistic.type === selected)
          .map((statistic, index) => (
            <div key={index} className="flex gap-2 pl-2 py-2">
              <div className="w-64 text-metal">{statistic.label}</div>
              <div className="w-32 text-lg text-navy">
                {formatSeconds(statistic.current)}
              </div>
              {!!statistic.previous && (
                <DataTag
                  type={statistic.trend}
                  color={statistic.color}
                  label={statistic.delta ? formatSeconds(statistic.delta) : ""}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AverageTimeMiniStatistic;
