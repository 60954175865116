import { faker } from "@faker-js/faker";
import {
  IMessage,
  IMessageInteraction,
  IMessageResponse,
} from "features/message/message.interface";
import { InteractionEventType } from "features/interaction/interaction.interface";
import { ContactEngagementType } from "features/entity/entity.interfaces";
import {
  ANDROID_AGENTS,
  DESKTOP_AGENTS,
  IOS_AGENTS,
  LINUX_AGENTS,
  MOBILE_AGENTS,
  TABLET_AGENTS,
  WINDOWS_AGENTS,
} from "utils/devices";
import fakeContact from "./contact";
import { addDays, addHours } from "date-fns";
import { mergeRight, prop, sortBy } from "ramda";
import { transformRecipient } from "features/message/messageApi";

const defaultOptions = {
  isDetail: false,
  enhanceRecipientInteractions: false,
  recipientsCount: 100,
  engagementHighCount: 25,
  engagementStandardCount: 25,
};

const fakeMessage = ({
  override = {},
  options = defaultOptions,
}: {
  override?: Partial<IMessage>;
  options?: any;
}): IMessage => {
  options = mergeRight(defaultOptions, options);
  const sendTimestamp = faker.date.recent(10).toISOString();
  const engagedLists = [
    ...[...Array(options.recipientsCount)]
      .slice(0, options.engagementHighCount)
      .map(() => "high" as ContactEngagementType),
    ...[...Array(options.recipientsCount)]
      .slice(
        options.engagementHighCount,
        options.engagementHighCount + options.engagementStandardCount,
      )
      .map(() => "standard" as ContactEngagementType),
    ...[...Array(options.recipientsCount)]
      .slice(
        options.engagementHighCount + options.engagementStandardCount,
        options.recipientsCount,
      )
      .map(() => "none" as ContactEngagementType),
  ];

  const recipients = engagedLists
    .map((engagement) => {
      return {
        interactions:
          options.isDetail && engagement !== "none"
            ? ([...Array(faker.datatype.number({ min: 1, max: 10 }))].map(
                () => ({
                  actionCreationTimestamp: faker.date
                    .soon(3, sendTimestamp)
                    .toISOString(),
                  eventType: faker.helpers.arrayElement(
                    engagement === "high"
                      ? [
                          "human_forward:peer",
                          "human_reply:peer",
                          "human_link_click:peer",
                          "human_read:peer",
                        ]
                      : ["human_quick_read:peer", "human_glance:peer"],
                  ) as InteractionEventType,
                  deviceType: faker.helpers.arrayElement([
                    ...DESKTOP_AGENTS,
                    ...MOBILE_AGENTS,
                    ...WINDOWS_AGENTS,
                    ...IOS_AGENTS,
                    ...LINUX_AGENTS,
                    ...ANDROID_AGENTS,
                    undefined,
                  ]),
                  duration: 12,
                  url: faker.helpers.arrayElement(["", "www.wikipedia.com"]),
                  uuid: faker.datatype.uuid(),
                  isRevisit: faker.datatype.boolean(),
                }),
              ) as IMessageInteraction[])
            : [],
        contact: fakeContact({}),
        affectedByPrivacyRead: false,
        engagement,
      };
    })
    .sort((a, b) => {
      if (a.engagement === "high") return -1;
      if (b.engagement === "high") return 1;
      if (a.engagement === "standard") return -1;
      if (b.engagement === "standard") return 1;
      return 0;
    })
    .map((recipient) =>
      // this is always done by messageApi
      options.enhanceRecipientInteractions
        ? transformRecipient(recipient)
        : recipient,
    );

  return mergeRight(
    {
      messageId: faker.datatype.uuid(), // either the original message id or the group id
      isRemerged: true,
      messageSubject: faker.lorem.sentence(),
      emailSender: faker.internet.email(),
      sendTimestamp,
      lastInteractionTimestamp: faker.date.soon(3, sendTimestamp).toISOString(),
      stats: {
        totalReads:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalClicks:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalRevisits:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalReplies:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalGlances:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalPotentialForwards:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalQuickReads:
          recipients.length * faker.datatype.number({ min: 0, max: 25 }),
        totalRecipients: recipients.length,
        totalEngagedRecipients: recipients.length,
      },
      recipients: options.isDetail ? recipients : recipients.slice(0, 10),
    },
    override,
  );
};

const fakeMessages = (
  items: any[] = [],
  amount: number = 20,
): IMessageResponse => {
  return {
    results: [
      ...items,
      ...[...Array(amount - items.length)].map(() => fakeMessage({})),
    ].sort(
      (a, b) =>
        new Date(b.sendTimestamp).getTime() -
        new Date(a.sendTimestamp).getTime(),
    ),
  };
};

const fakeBarInsight = (granularity: "hour" | "day") => {
  const date = new Date(faker.date.recent(10).toISOString());
  return Array(granularity === "day" ? 7 : 24)
    .fill(0)
    .map((_, i) => ({
      interactions: faker.datatype.number({ min: 0, max: 10 }),
      read: faker.datatype.number({ min: 0, max: 10 }),
      quickRead: faker.datatype.number({ min: 0, max: 10 }),
      potentialForward: faker.datatype.number({ min: 0, max: 10 }),
      reply: faker.datatype.number({ min: 0, max: 10 }),
      click: faker.datatype.number({ min: 0, max: 10 }),
      glance: faker.datatype.number({ min: 0, max: 10 }),
      revisit: faker.datatype.number({ min: 0, max: 10 }),
      grouping: granularity === "day" ? addDays(date, i) : addHours(date, i),
    }));
};

const fakePieInsight = (
  variant:
    | "engagement-breakdown"
    | "interaction-by-hour-breakdown"
    | "interaction-by-device-breakdown"
    | "interaction-per-contact-breakdown"
    | "recipient-by-country-breakdown",
) => {
  switch (variant) {
    case "engagement-breakdown":
      return {
        highly_engaged: faker.datatype.number({ min: 0, max: 25 }),
        engaged: faker.datatype.number({ min: 0, max: 25 }),
        unengaged: faker.datatype.number({ min: 0, max: 25 }),
      };
    case "interaction-by-hour-breakdown":
      return Array(24)
        .fill(0)
        .map((_, i) => ({
          hour: i + 1,
          count: faker.datatype.number({ min: 0, max: 10 }),
        }));
    case "interaction-by-device-breakdown":
      return Array(faker.datatype.number({ min: 1, max: 10 }))
        .fill(0)
        .map(() => ({
          deviceType: faker.helpers.arrayElement([
            ...DESKTOP_AGENTS,
            ...MOBILE_AGENTS,
            ...WINDOWS_AGENTS,
            ...TABLET_AGENTS,
            ...IOS_AGENTS,
            ...LINUX_AGENTS,
            ...ANDROID_AGENTS,
            undefined,
          ]),
          count: faker.datatype.number({ min: 0, max: 10 }),
        }));
    case "interaction-per-contact-breakdown":
      return {
        zero_to_five: faker.datatype.number({ min: 0, max: 25 }),
        six_to_ten: faker.datatype.number({ min: 0, max: 25 }),
        eleven_or_more: faker.datatype.number({ min: 0, max: 25 }),
      };

    case "recipient-by-country-breakdown":
      return Array(faker.datatype.number({ min: 0, max: 10 }))
        .fill(0)
        .map(() => ({
          countryCode: faker.helpers.arrayElement([
            null,
            faker.address.countryCode("alpha-2"),
          ]),
          count: faker.datatype.number({ min: 1, max: 10 }),
        }));
  }
};

const fakeMailboxes = ({
  min = 2,
  max = 3,
}: {
  min?: number;
  max?: number;
}) => ({
  availableMailboxes: sortBy(
    prop("email"),
    Array(faker.datatype.number({ min, max }))
      .fill(0)
      .map((_, i) => ({
        email: faker.internet.email(),
      })),
  ).map((item, i) => ({
    ...item,
    primary: i === 0 ? true : false,
  })),
});

export {
  fakeMessage,
  fakeMessages,
  fakeBarInsight,
  fakePieInsight,
  fakeMailboxes,
};
