import FilterTile, { FilterTileColor } from "components/FilterTile";
import {
  IEnhancedEvent,
  InteractionEventType,
} from "features/interaction/interaction.interface";
import enhancedInteractions from "../../../features/interaction/enhancedInteractions.json";

const InteractionFilters = ({
  active,
  onChange,
  stats,
  filteredStats,
}: {
  active?: InteractionEventType;
  onChange: (value?: InteractionEventType) => void;
  stats?: { [key in InteractionEventType]?: number };
  filteredStats?: { [key in InteractionEventType]?: number };
}) => {
  const options = [
    {
      pluralLabel: "Total interactions",
      bgColor: "metal",
    },
    ...enhancedInteractions,
  ] as IEnhancedEvent[];
  if (!stats) return <></>;
  return (
    <div>
      <div className="flex items-center pb-2 text-base text-metal">
        <i className="icon-filter pr-1" />
        Quick insights
      </div>
      <div className="flex flex-row flex-nowrap gap-x-2 overflow-x-scroll whitespace-nowrap pb-6">
        {options.map((option, i) => (
          <FilterTile
            key={i}
            value={stats[option.id || "interactions"]}
            filteredCount={
              filteredStats
                ? filteredStats[option.id || "interactions"]
                : undefined
            }
            selected={option.id === active}
            color={option.bgColor as FilterTileColor}
            label={
              (stats[option.id || "interactions"] || 0) > 1
                ? option.pluralLabel
                : option.label
            }
            size="large"
            onToggle={() => onChange(option.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default InteractionFilters;
