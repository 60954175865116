import { Row } from "./Table";
import { ICellType, ITableField } from "./Table.interfaces";

const TableSkeletonRow = ({ fields }: { fields: ITableField[] }) => {
  const cells: any[] = [];

  // identifier skeleton
  cells.push(
    <div className="flex items-center gap-2 pl-4 pr-12">
      <div className="h-8 w-8 rounded bg-dust"></div>
      <div className="h-3 w-[150px] rounded bg-dust-dark"></div>
    </div>,
  );

  const skeletons = {
    "progress-bar": (
      <div className="flex items-center justify-center">
        <div className="mx-4 h-3 w-[20px] rounded bg-dust"></div>
        <div className="flex flex-col gap-1 px-4">
          <div className="mt-2 h-2 w-[128px] rounded-full bg-dust"></div>
          <div className="h-3 w-[40px] rounded bg-dust-light"></div>
        </div>
      </div>
    ),
    "relationship-scores": (
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-center">
          <div className="ml-4 mr-8 h-3 w-[20px] rounded bg-dust"></div>
          <div className="h-3 w-[80px] rounded-full bg-dust"></div>
          <div className="h-3 w-[80px] rounded bg-dust-light"></div>
        </div>
        <div className="flex items-center justify-center">
          <div className="ml-4 mr-8 h-3 w-[20px] rounded bg-dust"></div>
          <div className="h-3 w-[80px] rounded-full bg-dust"></div>
          <div className="h-3 w-[80px] rounded bg-dust-light"></div>
        </div>
      </div>
    ),
    trend: (
      <div className="flex w-[120px] items-center justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    text: (
      <div className="flex w-[160px] items-center px-6">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    interaction: (
      <div className="flex flex-col gap-1 px-6">
        <div className="h-3 w-[50px] rounded-full bg-dust"></div>
        <div className="h-3 w-[128px] rounded bg-dust-dark"></div>
      </div>
    ),
    "email-breakdown": (
      <div className="flex items-center gap-12 px-6">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    "rank-tag": (
      <div className="flex w-[120px] items-center justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    "average-time": (
      <div className="flex w-[120px] items-center justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    "color-tag": (
      <div className="flex w-[120px] items-center justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    contact: (
      <div className="flex w-[120px] items-center justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
    identifier: (
      <div className="flex items-center gap-2 pl-4 pr-12">
        <div className="h-8 w-8 rounded bg-dust"></div>
        <div className="h-3 w-[150px] rounded bg-dust-dark"></div>
      </div>
    ),
    "multiple-trend": (
      <div className="flex w-[120px] items-center gap-4 justify-center">
        <div className="h-3 w-[40px] rounded bg-dust"></div>
        <div className="h-3 w-[40px] rounded bg-dust"></div>
      </div>
    ),
  };

  fields.forEach((field) => {
    cells.push(skeletons[field.type as ICellType]);
  });

  return <Row cells={cells} />;
};

export default TableSkeletonRow;
