import { pathOr, propOr } from "ramda";
import { safeJsonParse } from "utils/string";
import trend from "utils/trend";

const viewerMetric = (metric: string, row: any, defaultValue: any = 0) =>
  pathOr(defaultValue, ["viewer", metric], row);

export function extractSharedMetrics(
  row: any,
  personal: boolean,
): {
  rank: string;
  status: string;
  segment: string;
  relationshipScore: number;
  relationshipScoreTrend: string;
  colleaguesInteracting: number;
  interest: number;
  interestTrend: "increase" | "decrease" | "maintain";
  effort: number;
  effortTrend: "increase" | "decrease" | "maintain";
  lastInteraction: { date: string; contact: string; colleague: string };
  lastMeeting: { date: string; contact: string; colleague: string };
  viewerStatus: string;
  viewerInterest: number;
  viewerEffort: number;
  viewerRelationshipScore: number;
  viewerRelationshipScoreTrend: string;
  viewerLastInteraction: { date: string; contact: string; colleague: string };
  viewerLastMeeting: { date: string; contact: string; colleague: string };
  viewerEmailInbound: number;
  viewerEmailOutbound: number;
  viewerReplyResponseTime: number;
  viewerReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  replyResponseTime: number;
  replyResponseTimeTrend: "increase" | "decrease" | "maintain";
  firstReplyResponseTime: number;
  firstReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  viewerFirstReplyResponseTime: number;
  viewerFirstReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  theirReplyResponseTime: number;
  theirReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  viewerTheirFirstReplyResponseTime: number;
  viewerTheirFirstReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  theirFirstReplyResponseTime: number;
  theirFirstReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
  viewerTheirReplyResponseTime: number;
  viewerTheirReplyResponseTimeTrend: "increase" | "decrease" | "maintain";
} {
  return {
    rank: personal
      ? viewerMetric("fact_table.rank", row, 3)
      : row["fact_table.rank"],
    segment: row["fact_table.segment"],
    status: row["fact_table.activity"],
    relationshipScoreTrend: row["fact_table.trend"],
    relationshipScore: row["fact_table.relationship_score_current"],
    colleaguesInteracting: personal
      ? viewerMetric("fact_table.colleague_count_current", row)
      : row["fact_table.colleague_count_current"],
    interest: row["fact_table.interest_current"],
    interestTrend: trend(
      row["fact_table.interest_current"],
      row["fact_table.interest_prev"],
    ),
    effort: row["fact_table.effort_current"],
    effortTrend: trend(
      row["fact_table.effort_current"],
      row["fact_table.effort_prev"],
    ),
    lastInteraction: safeJsonParse(
      propOr("{}", "fact_table.last_interaction_details", row),
    ),
    lastMeeting: safeJsonParse(
      propOr("{}", "fact_table.last_meeting_details", row),
    ),
    viewerInterest: viewerMetric("fact_table.interest_current", row),
    viewerRelationshipScore: viewerMetric(
      "fact_table.relationship_score_current",
      row,
    ),
    viewerRelationshipScoreTrend: viewerMetric("fact_table.trend", row, ""),
    viewerStatus: viewerMetric("fact_table.activity", row, ""),
    viewerEffort: viewerMetric("fact_table.effort_current", row),
    viewerEmailInbound: viewerMetric("fact_table.inbound_current", row),
    viewerEmailOutbound: viewerMetric("fact_table.outbound_current", row),
    viewerLastInteraction: safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_interaction_details"], row),
    ),
    viewerLastMeeting: safeJsonParse(
      pathOr("{}", ["viewer", "fact_table.last_meeting_details"], row),
    ),
    viewerReplyResponseTime: viewerMetric(
      "fact_table.reply_response_time_current",
      row,
    ),
    viewerReplyResponseTimeTrend: trend(
      viewerMetric("fact_table.reply_response_time_current", row),
      viewerMetric("fact_table.reply_response_time_prev", row),
    ),
    replyResponseTime: propOr(0, "fact_table.reply_response_time_current", row),
    replyResponseTimeTrend: trend(
      propOr(0, "fact_table.reply_response_time_current", row),
      propOr(0, "fact_table.reply_response_time_prev", row),
    ),
    firstReplyResponseTime: propOr(
      0,
      "fact_table.first_reply_response_time_current",
      row,
    ),
    firstReplyResponseTimeTrend: trend(
      propOr(0, "fact_table.first_reply_response_time_current", row),
      propOr(0, "fact_table.first_reply_response_time_prev", row),
    ),
    viewerFirstReplyResponseTime: viewerMetric(
      "fact_table.first_reply_response_time_current",
      row,
    ),
    viewerFirstReplyResponseTimeTrend: trend(
      viewerMetric("fact_table.first_reply_response_time_current", row),
      viewerMetric("fact_table.first_reply_response_time_prev", row),
    ),
    theirReplyResponseTime: propOr(
      0,
      "fact_table.reply_out_response_time_current",
      row,
    ),
    theirReplyResponseTimeTrend: trend(
      propOr(0, "fact_table.reply_out_response_time_current", row),
      propOr(0, "fact_table.reply_out_response_time_prev", row),
    ),
    viewerTheirFirstReplyResponseTime: viewerMetric(
      "fact_table.first_reply_out_response_time_current",
      row,
    ),
    viewerTheirFirstReplyResponseTimeTrend: trend(
      viewerMetric("fact_table.first_reply_out_response_time_current", row),
      viewerMetric("fact_table.first_reply_out_response_time_prev", row),
    ),
    theirFirstReplyResponseTime: propOr(
      0,
      "fact_table.first_reply_out_response_time_current",
      row,
    ),
    theirFirstReplyResponseTimeTrend: trend(
      propOr(0, "fact_table.first_reply_out_response_time_current", row),
      propOr(0, "fact_table.first_reply_out_response_time_prev", row),
    ),
    viewerTheirReplyResponseTime: viewerMetric(
      "fact_table.reply_out_response_time_current",
      row,
    ),
    viewerTheirReplyResponseTimeTrend: trend(
      viewerMetric("fact_table.reply_out_response_time_current", row),
      viewerMetric("fact_table.reply_out_response_time_prev", row),
    ),
  };
}
