import { ContactEngagementType } from "features/entity/entity.interfaces";

const EmailEngagedEmptyBucket = ({
  allEngaged,
  label,
  engagement,
}: {
  allEngaged?: boolean;
  label: string;
  engagement: ContactEngagementType;
}) => (
  <div className="relative mx-auto hidden h-full max-w-[190px] flex-col items-center justify-center gap-2 px-4 text-center text-lg font-bold text-navy lg:flex">
    <i className="icon-activity text-3xl" />
    {allEngaged ? "All contacts have engaged with this email" : `0${label}s`}
    <div className="absolute bottom-4 px-4 text-center text-base font-normal text-metal">
      {engagement === "high" && (
        <>
          Contacts who Read, Click, Revisit or Reply to this email will show up
          here.
          <p className="mt-4">
            We also show contacts who have potentially forwarded this email
            here.
          </p>
        </>
      )}
      {engagement === "standard" && (
        <>Contacts who Quick read or Glance at this email will show up here.</>
      )}
    </div>
  </div>
);

export default EmailEngagedEmptyBucket;
