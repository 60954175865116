import TextInput from "components/TextInput";
import {
  ContactEngagementType,
  contactEngagementTypes,
} from "features/entity/entity.interfaces";
import {
  InteractionEventType,
  IEnhancedEvent,
} from "features/interaction/interaction.interface";
import { useGetMessageQuery } from "features/message/messageApi";
import { useState } from "react";
import enhancedInteractions from "features/interaction/enhancedInteractions.json";
import EmailEngagedBucket from "./EmailEngagedBucket";
import EmailDetailError from "./EmailDetailError";
import EmailDetailOverviewSkeleton from "./EmailDetailOverviewSkeleton";
import FilterTile, { FilterTileColor } from "components/FilterTile";
import env from "config";
import { useTrackFilterOptions } from "utils/trackOptions";

const BLAST_EMAIL_THRESHOLD =
  Number(env("REACT_APP_BLAST_EMAIL_THRESHOLD")) || 24;

const RecipientAffectedByPrivacyReadInfo = () => (
  <div className="px-4 pb-4">
    <div className="py-1 px-2 rounded-sm bg-dust w-min whitespace-nowrap">
      <div className="text-xs items-center flex text-metal-dark">
        <i className="icon-warning-partial text-xl leading-none mr-1"></i>
        At least one contact has interacted with this email in an email client
        which limits tracking.{" "}
        <button className="font-bold" id="intercom-cta-mpp">
          Click here to learn more.
        </button>
      </div>
    </div>
  </div>
);

const EmailDetailOverview = ({ id }: { id: string }) => {
  const [trackOptions] = useTrackFilterOptions();
  const {
    data: message,
    isError: isMessageError,
    isLoading: isMessageLoading,
  } = useGetMessageQuery({ id, trackOptions });
  const [active, setActive] = useState<InteractionEventType | undefined>();
  const [expanded, setExpanded] = useState<ContactEngagementType | "all">(
    "all",
  );
  const [searchValue, setSearchValue] = useState("");
  const getInteractionTypes = (stats?: { [key: string]: number }) => {
    let types = [];
    if (!!stats?.totalPotentialForwards) {
      types.push("potentialForward");
    }
    if (!!stats?.totalReplies) {
      types.push("reply");
    }
    if (!!stats?.totalRevisits) {
      types.push("revisit");
    }
    if (!!stats?.totalClicks) {
      types.push("click");
    }
    if (!!stats?.totalReads) {
      types.push("read");
    }
    if (!!stats?.totalQuickReads) {
      types.push("quickRead");
    }
    if (!!stats?.totalGlances) {
      types.push("glance");
    }

    return types;
  };

  const options = [
    {
      label: "Contact",
      pluralLabel: "Contacts",
      bgColor: "metal",
    },
    ...enhancedInteractions.filter((e) =>
      getInteractionTypes(message?.stats).includes(e.id),
    ),
  ] as IEnhancedEvent[];

  const getTotal = (id?: InteractionEventType) => {
    switch (id) {
      case "click":
        return message?.stats.totalClicks;
      case "glance":
        return message?.stats.totalGlances;
      case "read":
        return message?.stats.totalReads;
      case "reply":
        return message?.stats.totalReplies;
      case "potentialForward":
        return message?.stats.totalPotentialForwards;
      case "quickRead":
        return message?.stats.totalQuickReads;
      case "revisit":
        return message?.stats.totalRevisits;
      default:
        return message?.stats.totalRecipients;
    }
  };
  const filteredRecipients = (engagementType?: ContactEngagementType) =>
    message?.recipients.filter(({ engagement, interactions, contact }) => {
      if (engagementType && engagement !== engagementType) return false;
      if (
        active &&
        interactions.filter(
          ({ eventType, isRevisit }) =>
            active === eventType || (active === "revisit" && isRevisit),
        ).length === 0
      )
        return false;
      if (searchValue)
        return (
          contact.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
          (contact.company ? contact.company.displayName : "")
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );

      return true;
    }) || [];

  if (isMessageError) return <EmailDetailError />;
  if (isMessageLoading || !message) return <EmailDetailOverviewSkeleton />;
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex flex-col bg-gradient-to-b from-dust-light to-white px-4 pt-4 text-base">
        <div className="flex items-center pb-2 text-base text-metal">
          <i className="icon-filter pr-1" />
          Quick insights
        </div>
        <div className="flex flex-row gap-x-2 overflow-x-scroll pb-6">
          {options.map((option, i) => (
            <FilterTile
              key={i}
              size="large"
              selected={option.id === active}
              color={option.bgColor as FilterTileColor}
              label={
                (getTotal(option.id) || 0) > 1
                  ? option.pluralLabel
                  : option.label
              }
              value={getTotal(option.id)}
              filteredCount={
                searchValue
                  ? option.id
                    ? filteredRecipients().filter(
                        ({ interactions }) =>
                          interactions.filter(
                            ({ eventType }) => eventType === option.id,
                          ).length > 0,
                      ).length
                    : filteredRecipients().length
                  : undefined
              }
              onToggle={() => setActive(option.id)}
            />
          ))}
        </div>
        {message?.recipients.length > BLAST_EMAIL_THRESHOLD && (
          <div className="pb-4">
            <TextInput
              placeholder="Search for a contact or company"
              onChangeText={setSearchValue}
              value={searchValue}
              icon="icon-search"
              size="large"
              clearable
            />
          </div>
        )}
      </div>
      {message?.recipients.some(
        ({ affectedByPrivacyRead }) => affectedByPrivacyRead,
      ) && <RecipientAffectedByPrivacyReadInfo />}
      <div className="flex w-full flex-grow flex-col gap-2 overflow-y-scroll px-4 lg:flex-row lg:overflow-hidden">
        {contactEngagementTypes.map((engagementType, i) => (
          <EmailEngagedBucket
            key={i}
            allEngaged={
              engagementType === "none" &&
              message.recipients.length > 0 &&
              message.recipients.filter(
                ({ engagement }) => engagement === engagementType,
              ).length === 0
            }
            expanded={expanded === "all" ? "all" : expanded === engagementType}
            onExpand={() =>
              expanded === engagementType
                ? setExpanded("all")
                : setExpanded(engagementType)
            }
            searchValue={searchValue}
            recipients={filteredRecipients(engagementType)}
            engagement={engagementType}
          />
        ))}
      </div>
    </div>
  );
};

export default EmailDetailOverview;
