import classNames from "classnames";
import Button from "components/Button";
import { ContactEngagementType } from "features/entity/entity.interfaces";
import { IMessageRecipient } from "features/message/message.interface";
import { useState } from "react";
import toggleListItem from "utils/toggleListItem";
import EmailDetailInteractions from "./EmailDetailInteractions";
import EntityIdentifier from "components/EntityIdentifier";
import ContactInteractionIndicators from "pages/track/email/detail/ContactInteractionIndicators";
import EmailEngagedEmptyBucket from "pages/track/email/detail/EmailEngagedEmptyBucket";
import useMediaQuery from "utils/hooks/useMediaQuery";

const EmailEngagedBucket = ({
  recipients,
  engagement,
  allEngaged,
  searchValue,
  expanded,
  onExpand,
}: {
  recipients: IMessageRecipient[];
  engagement: ContactEngagementType;
  allEngaged?: boolean;
  searchValue?: string;
  expanded: boolean | "all";
  onExpand: VoidFunction;
}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const [showContactDetail, setShowContactDetail] = useState<string[]>([]);
  const label =
    engagement === "high"
      ? " highly engaged contact"
      : engagement === "standard"
        ? " engaged contact"
        : " unengaged contact";

  const sendEmails = () => {
    const emailStr = recipients.map(({ contact }) => contact.email).join(";");
    const emailLink = `mailto:?bcc=${emailStr}`;
    window.open(emailLink, "_blank");
  };

  // Contacted version of EmailEngagedBucket for the desktop view, the check is necessary because
  // Modal uses a different form on a smaller resolutions
  if (expanded === false && isDesktop)
    return (
      <div
        className={classNames(
          "flex w-12 flex-col items-center justify-between rounded-lg pb-4 pt-2",
          {
            "border border-green-dark bg-green text-green-darkest":
              engagement === "high",
            "border border-yellow-dark bg-yellow text-yellow-darkest":
              engagement === "standard",
            "border border-dust-dark bg-dust text-metal-dark":
              engagement === "none",
          },
        )}
      >
        <Button
          icon={classNames("icon-expand", {
            "text-green-darkest": engagement === "high",
            "text-yellow-darkest": engagement === "standard",
            "text-metal-dark": engagement === "none",
          })}
          color="transparent"
          size="small"
          onClick={onExpand}
          tooltip="Focus"
        />
        <div
          className="rotate-180 text-base"
          style={{ writingMode: "vertical-rl" }}
        >
          <span className="font-bold">{recipients.length}</span>
          {`${label}${recipients.length === 1 ? "" : "s"}`}
        </div>
      </div>
    );

  return (
    <div
      className={classNames("flex flex-col rounded-xl border lg:flex-grow", {
        "bg-gradient-to-b via-white to-white lg:min-w-[190px]":
          recipients.length === 0,
        "w-full": recipients.length > 0,
        "border-green-dark from-green-lightest": engagement === "high",
        "border-yellow-dark from-yellow-lightest": engagement === "standard",
        "border-dust-dark from-dust": engagement === "none",
      })}
    >
      <div
        className={classNames(
          "flex flex-row items-center justify-between rounded-t-xl p-2 pl-4 text-base",
          {
            "rounded-b-xl p-4 lg:rounded-none lg:rounded-t-xl":
              recipients.length === 0,
            "border-b border-green-dark bg-green text-green-darkest":
              engagement === "high",
            "border-b border-yellow-dark bg-yellow text-yellow-darkest":
              engagement === "standard",
            "border-b border-dust-dark bg-dust text-metal-dark":
              engagement === "none",
          },
        )}
      >
        <div>
          <span className="font-bold">{recipients.length}</span>
          {`${label}${recipients.length === 1 ? "" : "s"}`}
        </div>
        <div className="flex gap-2">
          {recipients.length > 0 && (
            <Button
              icon={classNames("icon-send", {
                "text-green-darkest": engagement === "high",
                "text-yellow-darkest": engagement === "standard",
                "text-metal-dark": engagement === "none",
              })}
              color="transparent"
              size="small"
              onClick={() => sendEmails()}
              tooltip="Email these contacts"
            />
          )}
          <Button
            icon={classNames(
              expanded && expanded !== "all" ? "icon-contract" : "icon-expand",
              {
                "text-green-darkest": engagement === "high",
                "text-yellow-darkest": engagement === "standard",
                "text-metal-dark": engagement === "none",
              },
            )}
            color="transparent"
            size="small"
            onClick={onExpand}
            tooltip={expanded && expanded !== "all" ? "Collapse" : "Focus"}
          />
        </div>
      </div>
      {expanded && (
        <>
          {!recipients.length && (
            <EmailEngagedEmptyBucket
              allEngaged={allEngaged}
              engagement={engagement}
              label={label}
            />
          )}
          <div
            className={classNames(
              "flex flex-col overflow-y-scroll lg:flex-grow",
              {
                "h-48 flex-grow": recipients.length > 0,
              },
            )}
          >
            <div className="flex flex-col rounded-b-xl text-base">
              {recipients.map((recipient, i) => (
                <div
                  key={i}
                  className="flex min-h-[67px] flex-col border-b border-dust px-2 py-4"
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex h-8 flex-row items-center">
                      <EntityIdentifier
                        type={recipient.isInternal ? "colleague" : "contact"}
                        id={
                          recipient.hasInsights
                            ? recipient.contact.uuid
                            : undefined
                        }
                        meta={{
                          email: recipient.contact.email,
                          phoneNumbers: recipient.contact.phoneNumbers,
                          onlyIdentifier: false,
                        }}
                        searchValue={searchValue}
                        title={recipient.contact.fullName}
                        description={recipient.contact.company?.displayName}
                      />
                    </div>
                    {engagement === "none" ? (
                      <div className="flex flex-row items-center">
                        {recipient.affectedByPrivacyRead && (
                          <i
                            data-tooltip-id="default-tooltip"
                            data-tooltip-content="Incomplete interaction data"
                            className="icon-warning-partial text-xl leading-none mr-1"
                          />
                        )}
                        <div className="rounded-sm bg-dust-light px-2 py-0.5 text-sm font-bold text-metal-dark">
                          Unread
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-2">
                        {recipient.affectedByPrivacyRead && (
                          <i
                            data-tooltip-id="default-tooltip"
                            data-tooltip-content="Incomplete interaction data"
                            className="icon-warning-partial text-xl leading-none"
                          />
                        )}
                        <ContactInteractionIndicators
                          interactions={recipient.interactions}
                          engagement={engagement}
                        />
                        <Button
                          icon={classNames(
                            showContactDetail.includes(recipient.contact.uuid)
                              ? "icon-up"
                              : "icon-down",
                          )}
                          color="transparent"
                          onClick={() =>
                            setShowContactDetail(
                              toggleListItem(
                                recipient.contact.uuid,
                                showContactDetail,
                              ),
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row">
                    {showContactDetail.includes(recipient.contact.uuid) && (
                      <EmailDetailInteractions
                        interactions={recipient.interactions}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailEngagedBucket;
