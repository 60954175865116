import { faker } from "@faker-js/faker";
import {
  ActivityType,
  RankType,
  SegmentType,
  TrendType,
} from "features/entity/entity.interfaces";

const fakeCubeResponse = ({ query = {}, data = [] }: any): any => {
  return {
    queryType: "regularQuery",
    slowQuery: false,
    pivotQuery: query,
    results: [
      {
        query,
        data,
        lastRefreshTime: "2023-03-14T12:07:05.711Z",
        annotation: {},
        dataSource: "default",
        dbType: "postgres",
        extDbType: "cubestore",
        external: false,
        slowQuery: false,
        total: data.length,
      },
    ],
  };
};

export const fakeCubeContactResponse = ({
  query,
  contacts,
}: {
  query: any;
  contacts: number;
}) =>
  fakeCubeResponse({
    query,
    data: Array.from({ length: contacts }).map(fakeCubeContact),
  });

const enumToKeyStringified = (value: any) =>
  Object.values(value)
    .filter((v) => !isNaN(Number(v)))
    .map(String);

const fakeCubeContact = () => ({
  "dim_contact.full_name": faker.name.fullName(),
  "dim_contact.uuid": faker.datatype.uuid(),
  "dim_company.display_name": faker.company.name(),
  "dim_contact.email": faker.internet.email(),
  "dim_contact.roles": "[]",
  "dim_contact.phone_numbers": JSON.stringify(
    Array.from({
      length: faker.datatype.number({ min: 0, max: 3 }),
    }).map(() => ({
      type: faker.helpers.arrayElement(["office", "mobile", null]),
      countryCode: faker.address.countryCode(),
      phone_number: faker.phone.number(),
    })),
  ),
  "fact_table.relationship_score_current": faker.datatype.number(100),
  "fact_table.rank": faker.helpers.arrayElement(enumToKeyStringified(RankType)),
  "fact_table.activity": faker.helpers.arrayElement(
    enumToKeyStringified(ActivityType),
  ),
  "fact_table.trend": faker.helpers.arrayElement(
    enumToKeyStringified(TrendType),
  ),
  "fact_table.segment": faker.helpers.arrayElement(
    enumToKeyStringified(SegmentType),
  ),
  "fact_table.colleague_count_current": faker.datatype.number(10),
  "fact_table.interest_current": faker.datatype.number(20),
  "fact_table.interest_prev": faker.datatype.number(20),
  "fact_table.effort_current": faker.datatype.number(20),
  "fact_table.effort_prev": faker.datatype.number(20),
  "fact_table.last_interaction_details": JSON.stringify({
    date: faker.date.past(),
    contact: faker.name.fullName(),
    colleague: "",
  }),
  "fact_table.last_meeting_details": JSON.stringify({
    date: faker.date.past(),
    contact: faker.name.fullName(),
    colleague: "",
  }),
  "fact_table.inbound_current": faker.datatype.number(20),
  "fact_table.outbound_current": faker.datatype.number(20),
  "fact_table.reply_response_time_current": faker.datatype.number(10000),
  "fact_table.reply_response_time_prev": faker.datatype.number(10000),
  "fact_table.reply_out_response_time_current": faker.datatype.number(10000),
  "fact_table.reply_out_response_time_prev": faker.datatype.number(10000),
  "fact_table.first_reply_response_time_current": faker.datatype.number(10000),
  "fact_table.first_reply_response_time_prev": faker.datatype.number(10000),
  "fact_table.first_reply_out_response_time_current":
    faker.datatype.number(10000),
  "fact_table.first_reply_out_response_time_prev": faker.datatype.number(10000),
});

export default fakeCubeResponse;
