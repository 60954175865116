import classnames from "classnames";
import IconSort from "components/atoms/IconSort";
import { SortType } from "./Table.interfaces";

const Header = ({
  text,
  sortable,
  sortState,
  onSort,
  sticky,
}: {
  text: string;
  sortable?: boolean;
  sortState?: SortType;
  onSort?: () => void;
  sticky?: boolean;
}) => {
  const onKeyPressed = (e: any) => {
    // enter or space pressed
    if ((e.keyCode === 13 || e.keyCode === 32) && sortable && onSort) onSort();
  };
  return (
    <th
      className={classnames("border-b border-r border-dust py-2 font-normal", {
        "sticky left-0 z-10 bg-white pl-2": sticky,
      })}
    >
      <div
        tabIndex={sortable ? 0 : -1}
        onKeyDown={onKeyPressed}
        onClick={() => {
          sortable && onSort && onSort();
        }}
        className={classnames(
          "group flex items-center justify-between rounded p-2",
          {
            "cursor-pointer outline-none hover:bg-dust-light focus:ring focus:ring-mint":
              sortable,
          },
        )}
      >
        <div
          className={classnames("mr-6 text-metal", {
            "group-hover:text-metal-dark": sortable,
          })}
        >
          {text}
        </div>

        {sortable && <IconSort state={sortState} />}
      </div>
    </th>
  );
};

export default Header;
