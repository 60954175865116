export const getInitials = (name: string): string => {
  const denormalized = name
    .replace(/[^\p{L}\p{N} ]+/giu, "")
    .replace(/\s+/g, " ")
    .trim();
  if (denormalized.length === 0) {
    return "";
  } else if (denormalized.length <= 2) {
    return denormalized.toUpperCase();
  }
  return denormalized
    .split(" ")
    .map((value) => value[0].toUpperCase())
    .join("")
    .slice(0, 2);
};

export const capitalizeFirstLetter = (name: string): string => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export function safeJsonParse(value: string) {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Error parsing JSON in: ${value} with error: ${e}`);
    return {};
  }
}
